const useIdentifyEvent = (
  userId: string,
  userTraits: { [key: string]: string | number | boolean } = {}
) => {
  const { $identifyEvent } = useNuxtApp();
  $identifyEvent(userId, {
    ...userTraits,
  });
};

export default useIdentifyEvent;
